import {
  Typography as MUITypography,
  Skeleton,
  type TypographyProps,
} from '@mui/material';

interface ITypographyProps extends TypographyProps {
  loading?: boolean;
}

export const Typography: React.FC<ITypographyProps> = ({
  loading,
  sx,
  // children,
  mb,
  ...props
}) => {
  if (loading) {
    return (
      <Skeleton variant='rectangular' sx={{ ...sx, borderRadius: 1, mb }}>
        <MUITypography {...props} />
      </Skeleton>
    );
  }
  return <MUITypography mb={mb} {...props} sx={sx} />;
};
