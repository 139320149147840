import { ApolloError } from '@apollo/client';
import { type CredentialResponse, GoogleLogin } from '@react-oauth/google';

interface IGoogleSignInProps {
  onSuccess: (tokenId: string) => void;
  onError: (error: Error | ApolloError) => void;
}

export const GoogleSignInButton: React.FC<IGoogleSignInProps> = ({
  onSuccess,
  onError,
}) => {
  const handleGoogleSuccess = (googleResponse: CredentialResponse) => {
    const credential = googleResponse.credential;
    if (!credential) return;
    onSuccess(credential);
  };

  const handleGoogleFailure = () => {
    onError(new Error('Falha ao autenticar com google'));
  };

  return (
    <GoogleLogin
      onSuccess={handleGoogleSuccess}
      width={392}
      size='large'
      shape='rectangular'
      onError={handleGoogleFailure}
    />
  );
};
